import { axiosClient } from "../query/axiosClient";
import { SALES } from "../configs/endpoints";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"

const fetchSales = async (pageSize, pageIndex, field, searchContent) => {
    const url = SALES.SALES_LIST(pageSize, pageIndex, field, searchContent);
    const { data } = await axiosClient.get(url);
    return data;
}

export const useSales = (pageSize, open, field, searchContent, closed) => {
    return useInfiniteQuery({
        queryKey: ['sales', pageSize],
        queryFn: ({ pageParam = 0 }) => open ? fetchSalesOpen(pageSize, pageParam, field, searchContent) : 
                                                closed ? fetchSalesClosed(pageSize, pageParam, field, searchContent) :
                                                fetchSales(pageSize, pageParam, field, searchContent),
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.content.length < pageSize) return undefined;
            return allPages.length;
        },
        refetchOnMount: "always"
    })
}

const fetchSalesClosed = async (pageSize, pageIndex, field, searchContent) => {
    const url = SALES.SALES_CLOSED_LIST(pageSize, pageIndex, field, searchContent);
    const { data } = await axiosClient.get(url);
    return data;
}


const fetchSalesTable = async () => {
    const url = SALES.SALES_TABLE();
    const { data } = await axiosClient.get(url);
    return data;
}

export const useSalesTable = () => {
    return useQuery({
        queryKey: ["SalesTable"],
        queryFn: () => fetchSalesTable(),
        refetchOnMount: "always",
        cacheTime: 0
    })
}

export const useSalesOpen = (pageSize, pageIndex) => {
    return useQuery({
        queryKey: ["salesOpen", pageSize, pageIndex],
        queryFn: () => fetchSalesOpen(pageSize, pageIndex),
        refetchOnWindowFocus: "always",
        refetchOnMount: "always",
    });
}

const fetchSalesOpen = async (pageSize, pageIndex, field, searchContent) => {
    const url = SALES.SALES_OPEN_LIST(pageSize, pageIndex, field, searchContent);
    const { data } = await axiosClient.get(url);    
    return data;
}

const fetchSale = async (id) => {
    const url = SALES.SALE_ONE(id);
    const { data } = await axiosClient.get(url);
    return data;
}

export const useSale = (id) => {
    return useQuery({
        queryKey: ["sale", id],
        queryFn: () => fetchSale(id),
        enabled: !!id,
        cacheTime: 0,
    });
}