import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    Paper,
    Button
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Add, Edit, Remove } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { formatFloatToReal } from "../../common/utils";
import dayjs from 'dayjs';
import {
    CnpjTextMask,
    MoneyTextMask,
    NumberTextMask,
    CepTextMask,
    CelTextMask,
    InterCelTextMask,
    AlphaTextMask
} from '../../components/masks';

import { api } from '../../services/api';
import SelectAdornment from '../../components/forms/adornments/selectAdornment';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

function realToFloat(value) {
    let newValue = value.replace(/\./g, "");
    newValue = newValue.replace(",", ".");
    return parseFloat(newValue);
}


const FinInstitutionView = (props) => {
    const { id } = useParams();
    const [inputFields, setInputFields] = useState({
        "contatos": [],
        "emailsFinanceiros": [],
        "emailsContabeis": []
    });
    const [fieldsBack, setFieldsBack] = useState({});

    const [editing, setEditing] = useState(false);
    const [running, setRunning] = useState(false)
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const handleAddAttr = (target, idx, field) => {
        let contatosList = inputFields.contatos;
        contatosList[idx][field] = target;
        setInputFields((inputFields) => ({
            ...inputFields, contatos: contatosList
        }));
    };

    const updateFinInstitution = async (item, id) => {
        const resp = api.putFinInstitution(JSON.stringify(item), id);
        return resp;
    }

    const deleteFinInstitution = async id => {
        const resp = api.delFinInstitution(id);
        return resp;
    }

    const handleClose = () => {
        setRunning(false);
        setOpen(false);
    };

    const handleEdit = () => {
        setEditing(true);
        setInputFields({
            ...inputFields, limite: {...inputFields?.limite, valor: ""}
        })
    }

    const handleSubmit = async e => {
        setRunning(true);
        e.preventDefault();
        const newItem = { ...inputFields };
        const newLimiteValor = newItem?.limite?.valor;
        const cachedLimiteValor = fieldsBack?.limite?.valor;
        newItem.limite.valor = newLimiteValor !== "" ? realToFloat(newLimiteValor) : cachedLimiteValor;
        newItem.limiteCredito = newLimiteValor !== "" ? realToFloat(newLimiteValor) : cachedLimiteValor;
        const resp = await updateFinInstitution(newItem, id);
        if (resp) {
            setMessage("Instituição atualizada!");
            setSeverity("success");
            setInputFields(resp);
            setFieldsBack(resp);
        } else {
            setMessage("Erro ao atualizar instituição!");
            setSeverity("error");
            setInputFields(fieldsBack);
        }
        setOpen(true);
        setRunning(false);
        setEditing(false);
    }

    const cancelSubmit = () => {
        setInputFields(fieldsBack);
        setEditing(false);
    }

    useEffect(() => {
        let mounted = true;
        api.getOneInstitution(id)
            .then(resp => {
                if (mounted) {
                    setInputFields({
                        ...resp,
                        contatos: resp.contatos || []
                    });
                    setFieldsBack({
                        ...resp,
                        contatos: resp.contatos || []
                    });
                }
            })
        return () => mounted = false;
    }, [])

    return (
        <Container
            maxWidth="xg"
            sx={{ mt: 4, mb: 4 }}
            component="form"
            onSubmit={handleSubmit}>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>

            <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 }, borderRadius: '10px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            style={{ textTransform: 'none', fontSize: '10px', marginBottom: "20px" }}
                            startIcon={(<Edit style={{ fontSize: "10px" }} />)}
                            sx={{ mr: 2 }}
                            size="small"
                            variant="contained"
                            disabled={editing || running}
                            disableElevation
                            onClick={() => handleEdit(id)}
                        >
                            Editar
                        </Button>
                        <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                            <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                <Typography sx={{ fontSize: "12px" }}>Informações Gerais</Typography>

                            </AccordionSummary>
                            <AccordionDetails style={{ borderColor: "#fff" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            size='small'
                                            required
                                            disabled={running}
                                            InputProps={{
                                                readOnly: !editing,
                                                inputComponent: AlphaTextMask,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                            id="name"
                                            name="name"
                                            label="Nome/Razão Social"
                                            value={inputFields.razaoSocial}
                                            onChange={event => setInputFields({ ...inputFields, razaoSocial: event.target.value })}
                                            inputProps={{
                                                maxLength: 60
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            size='small'
                                            disabled={running}
                                            InputProps={{
                                                readOnly: !editing,
                                                inputComponent: CnpjTextMask,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                            id="cnpj"
                                            name="cnpj"
                                            label="CNPJ"
                                            value={inputFields.cnpj}
                                            onChange={event => setInputFields({ ...inputFields, cnpj: event.target.value })}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            size='small'
                                            disabled={running}
                                            id="limiteCredito"
                                            name="limiteCredito"
                                            label="Limite de Crédito"
                                            helperText={editing ? "Valor atual: " + formatFloatToReal(fieldsBack?.limite.valor) : ""}
                                            fullWidth
                                            onChange={event => setInputFields({ ...inputFields, limite: { ...inputFields?.limite, valor: event.target.value } })}
                                            value={editing ? inputFields?.limite?.valor?.toString() : formatFloatToReal(fieldsBack?.limite?.valor?.toString())}
                                            InputProps={{
                                                readOnly: running,
                                                endAdornment: <SelectAdornment readOnly={!editing} options={["USD", "BRL"]} value={inputFields?.limite?.moeda} onChange={val => setInputFields({ ...inputFields, limite: { ...inputFields.limite, moeda: val } })} />,
                                                inputComponent: editing ? MoneyTextMask : null,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#f6f6f6' }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            size='small'
                                            required
                                            disabled={running}
                                            InputProps={{
                                                readOnly: !editing,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                            id="status"
                                            name="status"
                                            label={inputFields.inativoDesde ? "Inativo Desde" : "Ativo Desde"}
                                            value={inputFields.inativoDesde ? dayjs(inputFields.inativoDesde).format('DD/MM/YYYY').toString() : dayjs(inputFields.ativoDesde).format('DD/MM/YYYY').toString()}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                            <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                <Typography sx={{ fontSize: "12px" }}>Endereço</Typography>

                            </AccordionSummary>
                            <AccordionDetails style={{ borderColor: "#fff" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            size='small'
                                            required
                                            disabled={running}
                                            InputProps={{
                                                readOnly: !editing,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                            id="logradouro"
                                            name="logradouro"
                                            label="Logradouro"
                                            value={inputFields.endereco && inputFields.endereco.logradouro}
                                            onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, logradouro: event.target.value } })}
                                            inputProps={{
                                                maxLength: 60
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            size='small'
                                            disabled={running}
                                            InputProps={{
                                                readOnly: !editing,
                                                inputComponent: NumberTextMask,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                            id="numero"
                                            name="numero"
                                            label="Número"
                                            value={inputFields.endereco && inputFields.endereco.numero}
                                            onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, numero: event.target.value } })}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            size='small'
                                            required
                                            disabled={running}
                                            InputProps={{
                                                readOnly: !editing,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: { fontSize: "12px" }
                                            }}
                                            id="bairro"
                                            name="bairro"
                                            label="Bairro"
                                            value={inputFields.endereco && inputFields.endereco.bairro}
                                            onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, bairro: event.target.value } })}
                                            inputProps={{
                                                maxLength: 30
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            size='small'
                                            disabled={running}
                                            InputProps={{
                                                readOnly: !editing,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: { fontSize: "12px" }
                                            }}
                                            id="complemento"
                                            name="complemento"
                                            label="Complemento"
                                            value={inputFields.endereco && inputFields.endereco.complemento}
                                            onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, complemento: event.target.value } })}
                                            inputProps={{
                                                maxLength: 20
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            size='small'
                                            required
                                            disabled={running}
                                            InputProps={{
                                                readOnly: !editing,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: { fontSize: "12px" },
                                            }}
                                            id="cidade"
                                            name="cidade"
                                            label="Cidade"
                                            value={inputFields.endereco && inputFields.endereco.cidade}
                                            onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, cidade: event.target.value } })}
                                            inputProps={{
                                                maxLength: 30
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            size='small'
                                            required
                                            disabled={running}
                                            InputProps={{
                                                readOnly: !editing,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                            id="estado"
                                            name="estado"
                                            label="Estado"
                                            value={inputFields.endereco && inputFields.endereco.estado}
                                            onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, estado: event.target.value } })}
                                            inputProps={{
                                                maxLength: 20
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            size='small'
                                            required
                                            disabled={running}
                                            InputProps={{
                                                readOnly: !editing,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                            id="pais"
                                            name="pais"
                                            label="País"
                                            value={inputFields.endereco && inputFields.endereco.pais}
                                            onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, pais: event.target.value } })}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            size='small'
                                            required
                                            disabled={running}
                                            InputProps={{
                                                readOnly: !editing,
                                                inputComponent: CepTextMask,
                                                style: { fontSize: "12px" },
                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                            id="cep"
                                            name="cep"
                                            label="Cep"
                                            value={inputFields.endereco && inputFields.endereco.zipCode}
                                            onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, zipCode: event.target.value } })}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                            <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                <Typography sx={{ fontSize: "12px" }}>Contatos</Typography>

                            </AccordionSummary>
                            <AccordionDetails style={{ borderColor: "#fff" }}>
                                {editing && <Grid xs={12} sm={12} marginBottom={1}>
                                    <ButtonGroup disabled={running} style={{ height: "14px" }} variant="outlined" size="small" aria-label="small button group">
                                        <Button
                                            disableElevation
                                            onClick={() => {
                                                let contatoList = inputFields.contatos;
                                                contatoList.push({
                                                    "nome": "",
                                                    "email": "",
                                                    "celular": "",
                                                    "telefone": ""
                                                });
                                                setInputFields({ ...inputFields, contatos: contatoList });
                                            }}>
                                            <Add style={{ fontSize: "12px" }} /></Button>
                                        <Button
                                            disableElevation
                                            onClick={() => {
                                                if (inputFields.contatos.length > 1) {
                                                    let contatoList = inputFields.contatos;
                                                    contatoList.pop();
                                                    setInputFields({ ...inputFields, contatos: contatoList });
                                                }
                                            }}>
                                            <Remove style={{ fontSize: "12px" }} /></Button>
                                    </ButtonGroup>
                                </Grid>}
                                <Grid container spacing={2}>
                                    {inputFields.contatos.map((field, idx) => {
                                        return (
                                            <React.Fragment key={idx}>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        disabled={running}
                                                        InputProps={{
                                                            readOnly: !editing,
                                                            style: { fontSize: "12px" },
                                                            sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            style: { fontSize: "12px" },
                                                        }}
                                                        size="small"
                                                        required
                                                        id={`"nome_"${idx}`}
                                                        name="nome"
                                                        label="Nome"
                                                        value={field["nome"]}
                                                        onChange={event => handleAddAttr(event.target.value, idx, "nome")}
                                                        inputProps={{
                                                            maxLength: 40
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        size="small"
                                                        disabled={running}
                                                        InputProps={{
                                                            readOnly: !editing,
                                                            style: { fontSize: "12px" },
                                                            sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            style: { fontSize: "12px" }
                                                        }}
                                                        id={`"email_"${idx}`}
                                                        name="email"
                                                        label="Email"
                                                        value={field["email"]}
                                                        onChange={event => handleAddAttr(event.target.value, idx, "email")}
                                                        inputProps={{
                                                            maxLength: 50
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <TextField
                                                        size="small"
                                                        disabled={running}
                                                        InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                        id={`"telefone_"${idx}`}
                                                        name="telefone"
                                                        label="Telefone"
                                                        value={field["telefone"]}
                                                        onChange={event => handleAddAttr(event.target.value, idx, "telefone")}
                                                        InputProps={{
                                                            readOnly: !editing,
                                                            inputComponent: InterCelTextMask,
                                                            style: { fontSize: "12px" },
                                                            sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <TextField
                                                        size="small"
                                                        disabled={running}
                                                        InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                        id={`"celular_"${idx}`}
                                                        name="celular"
                                                        label="Celular"
                                                        value={field["celular"]}
                                                        onChange={event => handleAddAttr(event.target.value, idx, "celular")}
                                                        InputProps={{
                                                            readOnly: !editing,
                                                            inputComponent: CelTextMask,
                                                            style: { fontSize: "12px" },
                                                            sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    {editing ?
                        <Grid item >
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sm={6}>
                                    <LoadingButton
                                        style={{ textTransform: 'none', fontSize: '10px' }}
                                        loading={running}
                                        type="submit"
                                        startIcon={(<SaveIcon style={{ fontSize: "10px" }} />)}
                                        color="primary"
                                        variant="contained"
                                        size="medium"
                                        disableElevation
                                    // sx={{ width: "100px" }}
                                    >
                                        Salvar
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LoadingButton
                                        style={{ textTransform: 'none', fontSize: '10px' }}
                                        loading={running}
                                        startIcon={(<CancelIcon style={{ fontSize: "10px" }} />)}
                                        color="primary"
                                        variant='outlined'
                                        // variant="outlined"
                                        size="medium"
                                        disableElevation
                                        onClick={() => cancelSubmit()}
                                    // sx={{ width: "100px" }}
                                    >
                                        Cancelar
                                    </LoadingButton>
                                </Grid>
                            </Grid>

                        </Grid>
                        : null}
                </Grid>
            </Paper>
        </Container>
    );
}

export default FinInstitutionView;
