import { useQueries } from "@tanstack/react-query";
import { axiosClient } from "../query/axiosClient";
import { RECEIVINGS } from "../configs/endpoints";

const fetchReceivingById = async (id) => {
    const url = RECEIVINGS.RECEIVINGS_ONE(id);
    const { data } = await axiosClient.get(url);
    return data;
}

export const useReceivingById = (ids) => {
    return useQueries(ids.map((id) => {
        return {
            queryKey: [`receiving-${id}`, id],
            queryFn: () => fetchReceivingById(id),
            refetchOnWindowFocus: "always",
            refetchOnMount: "always",
            enabled: true,
        }
    }));
}

export const getReceivingByIds = async (ids) => {
    if (ids.length === 0) return [];

    return await Promise.all(ids.map(id => fetchReceivingById(id)));
};