import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePrereceipt } from '../../apis/prereceipt';
import { useAccs } from '../../apis/accs';
import { useNavigate } from 'react-router-dom';
import {
    Autocomplete,
    Container,
    Button,
    Snackbar,
    Alert,
    Stack,
    Paper,
    Grid,
    Box,
    Typography,
    TextField,
    List,
    ListItem,
    InputAdornment,
    ButtonGroup,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import {
    Add,
    Save,
    Remove,
    UploadFile,
    FileDownload,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { formatFloatToReal, formatRealToFloat, realToFloat, sumReceivedValues } from '../../common/utils';
import { MoneyTextMask } from '../../components/masks';
import FormCard from '../../components/forms/formCard';
import { api } from '../../services/api';
import { set } from 'react-hook-form';

const PreReceiptConvert = (props) => {
    const fontSize = "12px";
    const { id } = useParams();
    const [inputFields, setInputFields] = useState({
        "valorRecebido": "",
        "preRecebimento": "",
        "acc": "",
        "files": [],
        'isBlocked': true,

    });
    const [prereceiving, setPreReceiving] = useState(null);
    const [srcAccs, setSrcAccs] = useState([]);
    const [valuesListAcc, setValuesListAcc] = useState([]);
    const [open, setOpen] = useState(false);
    const [running, setRunning] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [totalAvailable, setTotalAvailable] = useState(0);
    const [totalAvailableAcc, setTotalAvailableAcc] = useState(0);
    const { data, isLoading, isFetched } = usePrereceipt(id);

    const { data: accsData, isFetched: isFetchedAccs } = useAccs(10000, true);

    const navigate = useNavigate();

    const handleClose = () => {
        setRunning(false);
        setOpen(false);
        navigate(`/prereceiving`);
    };

    const baseReceiving = {
        "valorRecebido": "",
        "preRecebimento": "",
        "acc": "",
        "files": []
    };

    const handleSubmit = async (e, item) => {
        e.preventDefault();
        setRunning(true);
        if (inputFields.acc !== "") {
            const formData = new FormData();
            for (let i = 0; i < item.files.length; i++) {
                if (item.files[i]["file"] !== "" && item.files[i]["field"] !== "") {
                    formData.append(item.files[i]["field"], item.files[i]["file"]);
                }
            }
            const newItem = { ...item };
            newItem.valorRecebido = realToFloat(inputFields.valorRecebido);
            newItem.preRecebimento = id;
            newItem.acc = inputFields.acc;
            const jsonBody = JSON.stringify(newItem);
            const blob = new Blob([jsonBody], {
                type: 'application/json'
            });
            formData.append("body", blob);
            const data = await api.postReceivingPrereceiving(formData);
            if (data) {
                setSeverity("success");
                setMessage("Recebimento criado com sucesso!");
                setOpen(true);
                setInputFields(baseReceiving);
                setPreReceiving(null);

            } else {
                setSeverity("error");
                setMessage("Erro ao criar recebimento!");
                setOpen(true);
                setInputFields(baseReceiving);
                setPreReceiving(null);
            }
        }
    }

    const handleAccSelected = (value) => {
        if (value) {
            let valuesFiltered = [];
            valuesFiltered = srcAccs.filter(acc => acc["contratoBacen"] === value);
            setInputFields({ ...inputFields, acc: valuesFiltered[0]["id"] });
            setTotalAvailableAcc((valuesFiltered[0].valorMonetarioDolar - sumReceivedValues(valuesFiltered[0]?.recebimentos)).toFixed(2));
        } else {
            setInputFields({ ...inputFields, acc: "" });
        }
    }

    const handleAddName = (target, idx) => {
        let filesList = inputFields.files;
        filesList[idx]["field"] = target;
        setInputFields((inputFields) => ({
            ...inputFields, files: filesList
        }));
    };

    const handleAddFile = (target, idx) => {
        let filesList = inputFields.files;
        filesList[idx]["file"] = target.files[0];
        setInputFields((inputFields) => ({
            ...inputFields, files: filesList
        }));
    };

    const isErrorValue = useMemo(() => {
        if (inputFields.valorRecebido !== "" && (prereceiving?.valorTotal - prereceiving?.valorRecebido).toFixed(2) !== 0) {
            if (formatRealToFloat(inputFields.valorRecebido) > (prereceiving?.valorTotal - prereceiving?.valorRecebido).toFixed(2)) {
                return true;
            }
        }
        return false;
    }, [inputFields, prereceiving]);

    const isErrorValueAcc = useMemo(() => {
        if (inputFields.valorRecebido !== "" && (totalAvailableAcc) !== 0) {
            if (formatRealToFloat(inputFields.valorRecebido) > (totalAvailableAcc)) {
                return true;
            }
        }
        return false;
    }, [inputFields, totalAvailableAcc]);

    useEffect(() => {
        if (srcAccs) {
            let valuesFiltered = [];
            valuesFiltered = srcAccs.map(acc => acc["contratoBacen"]);
            setValuesListAcc(valuesFiltered);
            setTotalAvailable((prereceiving?.valorTotal - prereceiving?.valorRecebido).toFixed(2));
        }
    }, [prereceiving?.valorRecebido, prereceiving?.valorTotal, srcAccs]);

    if (isFetched && data && prereceiving == null) setPreReceiving(data);

    if (isFetchedAccs && accsData.pages.length > 0 && srcAccs.length === 0) {
        setSrcAccs(accsData?.pages[0]?.content);
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const handleChangeValue = (value) => {
        setInputFields({ ...inputFields, valorRecebido: value });
    }

    return (
        <Container component="form"
            maxWidth="xg"
            sx={{ mt: 4, mb: 4 }}
            onSubmit={(e) => handleSubmit(e, inputFields)}>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>

            <Paper variant="outlined" sx={{ p: { xs: 2, md: 2 }, borderRadius: '10px' }}>
                <Grid item xs={12} sm={12}>
                    <Box
                        sx={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            ml: 1,
                            mr: 1,
                            mb: 0
                        }}
                    >
                        <Typography
                            sx={{ fontSize: "18px" }}
                        >
                            Converter Pré-Recebimento em Recebimento
                        </Typography>

                    </Box>
                    <FormCard title="Pré-Recebimento">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: fontSize },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="venda"
                                    name="venda"
                                    label="Código da Venda"
                                    value={"KAV-" + prereceiving?.venda?.code || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    error={isErrorValue}
                                    size='small'
                                    required
                                    disabled={running}
                                    id="valor"
                                    name="valor"
                                    label="Valor do Pré-Recebimento (USD)"
                                    fullWidth
                                    helperText={"Valor disponível do pré-recebimento: $" + formatFloatToReal(totalAvailable)}
                                    value={inputFields.valorRecebido}
                                    onChange={event => handleChangeValue(event.target.value)}
                                    InputLabelProps={{ style: { fontSize: fontSize } }}
                                    InputProps={{
                                        style: { fontSize: fontSize },
                                        inputComponent: MoneyTextMask,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="dataRecebimento"
                                    name="dataRecebimento"
                                    label="Data do Pré-Recebimento"
                                    value={dayjs(prereceiving?.creationDate).format('DD/MM/YYYY').toString() || ""}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </FormCard>
                    <FormCard title="Acc">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    id="contrato"
                                    name="contrato"
                                    options={valuesListAcc}
                                    onChange={(event, value) => handleAccSelected(value)}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                        label="Código Bacen"
                                        helperText={"Valor disponível do acc: $" + formatFloatToReal(totalAvailableAcc)}
                                        error={isErrorValueAcc}
                                    />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </FormCard>
                    <FormCard title="Arquivos do Pré-Recebimento">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <List sx={{ width: '100%', maxWidth: 400, bgcolor: '#fff', display: 'flex', flexDirection: 'row', padding: 0 }}>
                                    {prereceiving?.arquivos && Object.entries(prereceiving?.arquivos).map(([key, value]) => (
                                        <ListItem key={key}>
                                            <Button style={{ textTransform: 'none' }} variant="outlined"
                                                disabled
                                                startIcon={<FileDownload />}
                                            >
                                                {key}
                                            </Button>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </FormCard>
                    <FormCard title="Adicionar Novos Arquivos">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <ButtonGroup disabled={running} style={{ height: "14px" }} variant="outlined" size="small" aria-label="small button group">
                                    <Button
                                        disableElevation
                                        onClick={() => {
                                            let filesList = inputFields.files;
                                            filesList.push({ "field": "", "file": "" });
                                            setInputFields({ ...inputFields, files: filesList });
                                        }}
                                    >
                                        <Add style={{ fontSize: "12px" }} /></Button>
                                    <Button
                                        disableElevation
                                        onClick={() => {
                                            if (inputFields.files.length > 1) {
                                                let filesList = inputFields.files;
                                                filesList.pop();
                                                setInputFields({ ...inputFields, files: filesList });
                                            }
                                        }}
                                    >
                                        <Remove style={{ fontSize: "12px" }} /></Button>
                                </ButtonGroup>
                            </Grid>
                            {inputFields.files.map((field, idx) => {
                                return (
                                    <React.Fragment key={idx}>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                disabled={running}
                                                id={`${idx + 1}`}
                                                name="filename"
                                                label={`Descrição ${idx + 1}`}
                                                value={inputFields.files[idx]["field"]}
                                                onChange={event => handleAddName(event.target.value, idx)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                disabled={running}
                                                id={`${idx + 1}`}
                                                name="arquivo"
                                                label={`Arquivo ${idx + 1}`}
                                                value={inputFields.files[idx]["file"].name}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment:
                                                        <Button
                                                            size="small"
                                                            component="label"
                                                            color="primary"
                                                            variant="outlined">
                                                            <input
                                                                hidden
                                                                accept="pdf/*"
                                                                multiple
                                                                type="file"
                                                                onChange={event => handleAddFile(event.target, idx)}
                                                            >
                                                            </input>
                                                            <UploadFile />
                                                        </Button>
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}></Grid>
                                    </React.Fragment>
                                )
                            })}
                        </Grid>
                    </FormCard>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container sx={{ m: 1 }}>
                        <LoadingButton
                            disabled={isErrorValue || isErrorValueAcc || inputFields.valorRecebido === '' || totalAvailableAcc === 0}
                            style={{ textTransform: 'none', fontSize: '11px' }}
                            loading={running}
                            type="submit"
                            startIcon={(<Save style={{ fontSize: "10px" }} />)}
                            color="primary"
                            variant="contained"
                            size="small"
                            disableElevation
                        >
                            Salvar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

export default PreReceiptConvert;