import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from "react";
import { isAdmin, isManager } from "../../services/auth";

import Autocomplete from '@mui/material/Autocomplete';
import FormCard from "../../components/forms/formCard";
import PageHeaderButton from '../../components/pageHeaderButton';
import ReceivingTable from "./receivingTable";
import TextField from '@mui/material/TextField';
import { api } from '../../services/api';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';

const Receiving = props => {
    const [receivingList, setReceivingList] = useState([]);

    const [srcReceivingList, setSrcReceivingList] = useState([]);
    const [field, setField] = useState("");
    const [valuesList, setValuesList] = useState([]);
    const [exportScreenOpen, setExportScreenOpen] = useState(false)
    const [exportDate, setExportDate] = useState({
        dataInicial: dayjs(new Date()),
        dataFinal: dayjs(new Date())
    })

    const mapFields = {
        "Kav": "vendaCode",
        "Cliente": "importadora",
        "Banco": "banco",
        "Contrato": "contratoBanco",
        "Bacen": "contratoBacen",
        "Data": "creationDate"
    }

    useEffect(() => {
        let mounted = true;
        api.getReceivings()
            .then(resp => {
                if (mounted && resp) {
                    setReceivingList(resp.page)
                    setSrcReceivingList(resp.page);
                }
            })
        return () => mounted = false;
    }, []);

    const handleFieldSelect = (value) => {
        if (value) {
            let valuesFiltered = [];
            if (mapFields[value] === "creationDate") {
                valuesFiltered = srcReceivingList.map(elem => dayjs(elem[mapFields[value]]).format('DD/MM/YYYY').toString());
            } else {
                valuesFiltered = srcReceivingList.map(elem => elem[mapFields[value]]);
            }
            setValuesList(valuesFiltered.filter((item, index) => valuesFiltered.indexOf(item) === index));
            setField(mapFields[value]);
        }
    };

    const handleValueSelect = (value) => {
        if (value && value.length > 0) {
            let receivingFiltered = [];
            if (field === "creationDate") {
                receivingFiltered = srcReceivingList.filter(a => value.includes(dayjs(a[field]).format('DD/MM/YYYY').toString()));
            } else {
                receivingFiltered = srcReceivingList.filter(a => value.includes(a[field]));
            }
            setReceivingList(receivingFiltered);
        } else {
            setReceivingList(srcReceivingList);
        }
    };

    return (
        <Container maxWidth="xg" sx={{ mt: 4, mb: 4 }}>
            <Dialog
                open={exportScreenOpen}
                onClose={() => setExportScreenOpen(false)}
            >
                <DialogTitle>Exportar dados de recebimentos</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                        <Box sx={{m:1}}>
                            <Box sx={{m:1, marginBottom: '20px'}}>
                                <DatePicker
                                    label="Data inicial"
                                    value={exportDate.dataInicial}
                                    onChange={(e) => setExportDate((prev) => ({
                                        ...prev,
                                        dataInicial: e
                                    }))}
                                    disableFuture
                                />
                            </Box>  
                            <Box sx={{m:1}}>
                                <DatePicker
                                    label="Data final"
                                    value={exportDate.dataFinal}
                                    onChange={(e) => setExportDate((prev) => ({
                                        ...prev,
                                        dataFinal: e
                                    }))}
                                    disableFuture
                                />
                            </Box>
                        </Box>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setExportScreenOpen(false)}}>Cancelar</Button>
                    <Button onClick={async () => {
                        await api.getReceivingsCSV(exportDate.dataInicial.format("DD-MM-YYYY"), exportDate.dataFinal.format("DD-MM-YYYY"))
                        setExportScreenOpen(false)
                    }}>Exportar</Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={12}>
                <PageHeaderButton
                    title="Recebimentos"
                    path="/receiving"
                    canExport={true}
                    exportCallback={() => setExportScreenOpen(true)}
                    canCreate={isAdmin() || isManager()}></PageHeaderButton>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 }, borderRadius: '10px' }}>
                        <Grid item xs={12} sm={12}>
                            <FormCard title="Filtros">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={2}>
                                        <Autocomplete
                                            size="small"
                                            disablePortal
                                            id="field"
                                            getOptionLabel={(option) => option}
                                            options={Object.keys(mapFields)}
                                            onChange={(event, value) => handleFieldSelect(value)}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                                label="Campo a ser filtrado" />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <Autocomplete
                                            multiple
                                            size="small"
                                            disablePortal
                                            id="value"
                                            getOptionLabel={(option) => option}
                                            options={valuesList}
                                            onChange={(event, value) => handleValueSelect(value)}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Conteúdo"
                                                InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                            />}
                                        />
                                    </Grid>
                                </Grid>
                            </FormCard>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <ReceivingTable rows={receivingList} />
                </Grid>
            </Grid>
        </Container>
    );

};

export default Receiving;
