import { useQueries, useInfiniteQuery } from "@tanstack/react-query";
import { axiosClient } from "../query/axiosClient";
import { ACCS } from "../configs/endpoints";

const fetchAccById = async (id) => {
    const url = ACCS.ACCS_ONE(id);
    const { data } = await axiosClient.get(url);
    return data;
}

export const useAccsByIds = (ids) => {
    return useQueries(ids.map((id) => {
        return {
            queryKey: [`acc-${id}`, id],
            queryFn: () => fetchAccById(id),
            enabled: !!id,
            cacheTime: 0,
        }
    }));
}

export const getAccsByIds = async (ids) => {
    if (ids.length === 0) return [];

    return await Promise.all(ids.map(id => fetchAccById(id)));
};

const fetchAccsOpen = async (pageSize, pageIndex, field, searchContent) => {
    const url = ACCS.ACCS_OPEN_LIST(pageSize, pageIndex, field, searchContent);
    const { data } = await axiosClient.get(url);
    return data;
}

const fetchAccsClosed = async (pageSize, pageIndex, field, searchContent) => {
    const url = ACCS.ACCS_CLOSED_LIST(pageSize, pageIndex, field, searchContent);
    const { data } = await axiosClient.get(url);
    return data;
}

const fetchAccs = async (pageSize, pageIndex, field, searchContent) => {
    const url = ACCS.ACCS_LIST(pageSize, pageIndex, field, searchContent);
    const { data } = await axiosClient.get(url);
    return data;
}

export const useAccs = (pageSize, open, field, searchContent, closed) => {
    return useInfiniteQuery({
        queryKey: ['accs', pageSize],
        queryFn: ({ pageParam = 0 }) => open ?
            fetchAccsOpen(pageSize, pageParam, field, searchContent) : 
            closed ? fetchAccsClosed(pageSize, pageParam, field, searchContent) :
            fetchAccs(pageSize, pageParam, field, searchContent),
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.content.length < pageSize) return undefined;
            return allPages.length;
        },
        refetchOnMount: "always"
    });
}