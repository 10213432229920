import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PropTypes from 'prop-types';

export default function SelectAdornment({ value, options, onChange, readOnly = false }) {
    const [openSelect, setOpenSelect] = React.useState(false);
    const selectRef = useRef(null);

    const handleOptionClick = (optionValue) => {
        onChange(optionValue);
        setOpenSelect(false);
    };

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setOpenSelect(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Box 
            ref={selectRef}
            sx={{ 
                display: "flex", 
                alignItems: "center", 
                cursor: !readOnly && "pointer", 
                position: "relative"
            }} 
            onClick={() => !readOnly && setOpenSelect(!openSelect)}
            role="button"
            aria-haspopup="listbox"
            aria-expanded={openSelect}
        >
            <span>{value}</span>
            {!readOnly && (openSelect ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
            {openSelect && (
                <Box 
                    component="ul" 
                    role="listbox" 
                    sx={{ 
                        position: "absolute", 
                        top: "100%", 
                        left: 0, 
                        backgroundColor: "#fff", 
                        border: "1px solid #ccc", 
                        borderRadius: "4px", 
                        boxShadow: "0 2px 8px rgba(0,0,0,0.15)", 
                        zIndex: 10,
                        padding: 0,
                        margin: 0,
                        listStyle: "none"
                    }}
                >
                    {options.map(opt => (
                        <Box 
                            component="li" 
                            role="option" 
                            key={opt} 
                            onClick={() => handleOptionClick(opt)}
                            sx={{ 
                                padding: "8px 12px", 
                                cursor: "pointer",
                                '&:hover': { backgroundColor: "#f0f0f0" } 
                            }}
                        >
                            {opt}
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};

SelectAdornment.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
};
