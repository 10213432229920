import axios from "axios";
import Endpoint from "./endpoints";
import { getToken, setToken } from "./auth";

const axiosInstance = axios.create({
    baseURL: Endpoint.baseAddr,
    headers: {
        "Content-Type": "application/json"
    }
});

export const api = {
    login: async (payload) => {
        try {
            const resp = await axiosInstance.post(Endpoint.login, payload);
            if (resp.status === 200) {
                setToken(resp.data);
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    postUser: async (payload) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.post(Endpoint.user, payload);
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getUsers: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.user);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getOneUser: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.user.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    putUser: async (payload, id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.put(Endpoint.user.concat(`/${id}`), payload);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    delUser: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.delete(Endpoint.user.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    postProduct: async (payload) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.post(Endpoint.product, payload);
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getProducts: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.product);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getOneProduct: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.product.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    putProduct: async (payload, id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.put(Endpoint.product.concat(`/${id}`), payload);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    delProduct: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.delete(Endpoint.product.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    postImporter: async (payload) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.post(Endpoint.importer, payload);
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getImporters: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.importer);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getOneImporter: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.importer.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    putImporter: async (payload, id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.put(Endpoint.importer.concat(`/${id}`), payload);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    delImporter: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.delete(Endpoint.importer.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    postExporter: async (payload) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.post(Endpoint.exporter, payload);
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getExporters: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.exporter);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getOneExporter: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.exporter.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    putExporter: async (payload, id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.put(Endpoint.exporter.concat(`/${id}`), payload);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    delExporter: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.delete(Endpoint.exporter.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    postFinInstitution: async (payload) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.post(Endpoint.fininstitution, payload);
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getFinInstitutions: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.fininstitution);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getOneInstitution: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.fininstitution.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    putFinInstitution: async (payload, id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.put(Endpoint.fininstitution.concat(`/${id}`), payload);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    delFinInstitution: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.delete(Endpoint.fininstitution.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    postReceiving: async (payload) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";
            const resp = await axiosInstance.post(Endpoint.receiving, payload);
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    postReceivingPrereceiving: async (payload) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";
            const resp = await axiosInstance.post(Endpoint.receiving.concat("/prerecebimento"), payload);
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getFileReceivings: async (id, filename) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.receiving.concat(`/${id}/${filename}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getReceivings: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.receiving.concat('?pageSize=100000000&pageNo=0'));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getReceivingsCSV: async (dataInicial, dataFinal) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.receiving.concat("/document.csv"), {
                params: {
                    dataInicial,
                    dataFinal
                },
                responseType: 'blob'
            })
            var url = window.URL.createObjectURL(resp.data);
            var a = document.createElement('a');
            a.href = url;
            a.download = `document_${dataInicial}_${dataFinal}.csv`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getOneReceiving: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.receiving.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    putReceiving: async (payload, id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.put(Endpoint.receiving.concat(`/${id}`), payload);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    delReceiving: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.delete(Endpoint.receiving.concat(`/${id}`));
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    postSale: async (payload) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";
            const resp = await axiosInstance.post(Endpoint.sale, payload);
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getSales: async (pageSize, pageNo) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.sale.concat(`?pageSize=${pageSize}&pageNo=${pageNo}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getOpenSales: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.sale.concat('/open?pageSize=100000000&pageNo=0'));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getDashboardSales: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.sale.concat('/dashboard'));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getDashboardFinInstitutions: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.fininstitution.concat('/dashboard'));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getOneSale: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.sale.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    putSale: async (payload, id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";
            const resp = await axiosInstance.put(Endpoint.sale.concat(`/${id}`), payload);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    delSale: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.delete(Endpoint.sale.concat(`/${id}`));
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    postAcc: async (payload) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";
            const resp = await axiosInstance.post(Endpoint.acc, payload);
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getAccs: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.acc.concat('?pageSize=100000000&pageNo=0'));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getOpenAccs: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.acc.concat('/open?pageSize=100000000&pageNo=0'));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getDashboardAccs: async () => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.acc.concat('/dashboard'));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getOneAcc: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.acc.concat(`/${id}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },
    
    getFileAcc: async (id, filename) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.acc.concat(`/${id}/${filename}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getFileSale: async (id, filename) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.get(Endpoint.sale.concat(`/${id}/${filename}`));
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    putAcc: async (payload, id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";
            const resp = await axiosInstance.put(Endpoint.acc.concat(`/${id}`), payload);
            if (resp.status === 200) {
                return resp.data;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    delAcc: async (id) => {
        try {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${getToken()}`;
            const resp = await axiosInstance.delete(Endpoint.acc.concat(`/${id}`));
            if (resp.status === 200) {
                return true;
            }
            return null;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
}
export default axiosInstance;