import React, { useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormCard from "./formCard";
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
    MoneyTextMask,
    CodeTextMask
} from '../masks';

export default function FormGeneralSales(props) {
    const { inputFields, setInputFields, running, exporter, clientList, productList } = props;
    const fontSize = "12px";

    const availableClients = useMemo(() => {
        return clientList.map(client => ({
            id: client.id,
            razaoSocial: client.razaoSocial,
        }));
    }, [clientList]);
    

    const availableProducts = useMemo(() => {
        return productList.map(product => product.nome);
    }, [productList]);

    const handleClientValueSelect = (value) => {
        if (!value) {
            setInputFields({ ...inputFields, importadora: "" });
            return;
        }

        if (typeof value === "object" && value !== null && value.hasOwnProperty('razaoSocial')) {
            value = value.razaoSocial; 
        }

        const formattedValue = value.trim().toLowerCase();
        
        const selectedClient = clientList.find(client => client.razaoSocial.trim().toLowerCase() === formattedValue);
    
      
        setInputFields({ ...inputFields, importadora: selectedClient.id, exportadora: exporter.id });
    };

    useEffect(() => {
    }, [availableClients]);
    

    const handleProductValueSelect = (value) => {
        if (!value) {
            setInputFields({ ...inputFields, produto: "" });
            return;
        };
        const selectedProduct = productList.find(product => product.nome === value);
        setInputFields({ ...inputFields, produto: selectedProduct.id });
    }

    return (
        <Grid item xs={12} sm={12}>
            <FormCard title="Informações Gerais">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size='small'
                            required
                            disabled={running}
                            id="code"
                            name="code"
                            label="Código"
                            fullWidth
                            value={inputFields.code}
                            onChange={event => setInputFields({ ...inputFields, code: event.target.value })}
                            InputProps={{
                                style: { fontSize: fontSize },
                                startAdornment: <InputAdornment position="start">KAV</InputAdornment>,
                                inputComponent: CodeTextMask,
                            }}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            inputProps={{
                                maxLength: 60
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size='small'
                            disabled
                            id="exportadora"
                            name="exportadora"
                            label="Exportadora"
                            defaultValue
                            value={exporter?.razaoSocial}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            InputProps={{ style: { fontSize: fontSize } }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        id="value"
                        options={availableClients}
                        getOptionLabel={(option) => option.razaoSocial || ""}  
                        onChange={(event, value) => handleClientValueSelect(value)}
                        filterOptions={(options, state) => 
                            options.filter(option =>
                                option.razaoSocial.toLowerCase().includes(state.inputValue.toLowerCase()) 
                            )
                        }
                        sx={{ width: "100%" }}
                        renderOption={(props, option) => (
                            <li {...props} key={option.id}>  
                                {option.razaoSocial}
                            </li>
                        )}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Cliente"
                            InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                            InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                        />}
                    />

                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            id="product"
                            getOptionLabel={(option) => option}
                            options={availableProducts}
                            onChange={(event, value) => handleProductValueSelect(value)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField
                                {...params}
                                label="Produto"
                                InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                            />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            size='small'
                            id="totalDolar"
                            name="totalDolar"
                            label="Total da Venda (USD)"
                            fullWidth
                            onChange={event => setInputFields({ ...inputFields, totalDolar: event.target.value })}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            value={inputFields.totalDolar}
                            InputProps={{
                                style: { fontSize: fontSize },
                                inputComponent: MoneyTextMask,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}>
                            < DatePicker
                                required
                                sx={{
                                    width: '100%',
                                    '& .MuiInputBase-input': {
                                        boxSizing: 'inherit',
                                        fontSize: fontSize,
                                    },
                                    '& .MuiFormLabel-root': {
                                        fontSize: fontSize,
                                        top: '-7px',
                                    },
                                }}
                                id="dataVenda"
                                name="dataVenda"
                                label="Data da venda"
                                format="DD/MM/YYYY"
                                value={inputFields.dataVenda}
                                onChange={event => setInputFields({ ...inputFields, dataVenda: event })}
                            />

                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </FormCard>
        </Grid>
    );
}