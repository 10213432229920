import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { CnpjTextMask } from "../masks";
import FormCard from "./formCard";

import { MoneyTextMask } from '../../components/masks';
import SelectAdornment from "./adornments/selectAdornment";

export default function FormGeneralBank(props) {
    const { inputFields, setInputFields, running } = props;
    const fontSize = "12px";

    return (
        <Grid item xs={12} sm={12}>
            <FormCard title="Informações Gerais">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size='small'
                            required
                            disabled={running}
                            id="razaoSocial"
                            name="razaoSocial"
                            label="Nome/Razão Social"
                            fullWidth
                            value={inputFields.razaoSocial}
                            onChange={event => setInputFields({ ...inputFields, razaoSocial: event.target.value })}
                            InputProps={{ style: { fontSize: fontSize } }}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            inputProps={{
                                maxLength: 60
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            size='small'
                            disabled={running}
                            id="cnpj"
                            name="cnpj"
                            label="CNPJ"
                            value={inputFields.cnpj}
                            onChange={event => setInputFields({ ...inputFields, cnpj: event.target.value })}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            InputProps={{
                                style: { fontSize: fontSize },
                                inputComponent: CnpjTextMask,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            required
                            size='small'
                            id="limiteCredito"
                            name="limiteCredito"
                            label="Limite de Crédito"
                            fullWidth
                            onChange={event => setInputFields({ ...inputFields, limite: { ...inputFields.limite, valor: event.target.value } })}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            value={inputFields?.limite?.valor?.toString()}
                            InputProps={{
                                style: { fontSize: fontSize },
                                inputComponent: MoneyTextMask,
                                endAdornment: <SelectAdornment value={inputFields?.limite?.moeda} onChange={(val) => setInputFields({ ...inputFields, limite: { ...inputFields.limite, moeda: val } })} options={["USD", "BRL"]} />
                            }}
                        />
                    </Grid>
                </Grid>
            </FormCard>
        </Grid>
    );
}