import { Chip, Container, Grid } from '@mui/material';
import React, { useState } from "react";
import { isAdmin, isManager } from '../../services/auth';

import Autocomplete from '@mui/material/Autocomplete';
import FormCard from "../../components/forms/formCard";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import PageHeaderButton from '../../components/pageHeaderButton';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SaleTable from "./saleTable";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingIndicator from "../../components/loadingIndicator";
import { isEmpty } from '../../util/utils';

const Sale = props => {
    const [valuesList, setValuesList] = useState([]);
    const [field, setField] = useState('');
    const [loadingData, setLoadingData] = useState(true);
    const [priority, setPriority] = useState("open");
    const [searchContent, setSearchContent] = useState('');
    const [searchTags, setSearchTags] = useState([]);

    const mapFields = {
        "Kav": "code",
        "Cliente": "razaoSocial",
        "Data": "dataVenda"
    };

    const handlePriorityChange = (event) => {
        setPriority(event.target.value);
    };

    const handleFieldChange = (_, value) => {
        if (value === null || value !== field) {
            setSearchContent("");
            setSearchTags([]);
        }
        setField(value);
    };

    const handleTagsChange = (_, value) => {
        setSearchTags(value);
        setSearchContent(value.join(','));
    };

    return (
        <Container maxWidth="xg" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>
                <PageHeaderButton
                    title="Vendas"
                    path="/sales"
                    canCreate={isAdmin() || isManager()}
                />
            </Grid>
            <Grid sx={{ display: loadingData ? "flex" : "none", justifyContent: "center" }} >
                <LoadingIndicator />
            </Grid>
            <Grid display={loadingData && "none"} container spacing={1}>
                <Grid item xs={12}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 }, borderRadius: '10px' }}>
                        <Grid item xs={12} sm={12}>
                            <FormCard title="Filtros">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontSize: "12px" }}>
                                                Considerar vendas:
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={handlePriorityChange}
                                                defaultValue="open"
                                            >
                                                <FormControlLabel
                                                    value="open"
                                                    control={<Radio style={{ height: "5px" }} size="small" />}
                                                    label={<Typography style={{ fontSize: "12px" }}>Não Quitadas</Typography>}
                                                />
                                                <FormControlLabel
                                                    value="closed"
                                                    control={<Radio size="small" />}
                                                    label={<Typography style={{ fontSize: "12px" }}>Quitadas</Typography>}
                                                />
                                                <FormControlLabel
                                                    value="all"
                                                    control={<Radio size="small" />}
                                                    label={<Typography style={{ fontSize: "12px" }}>Todas</Typography>}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Autocomplete
                                            size="small"
                                            disablePortal
                                            id="field"
                                            getOptionLabel={(option) => option}
                                            options={Object.keys(mapFields)}
                                            onChange={handleFieldChange} // Chamada da função handle para a mudança
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                                    label="Campo a ser filtrado"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <Autocomplete
                                            multiple
                                            freeSolo
                                            disabled={isEmpty(field)}
                                            size="small"
                                            disablePortal
                                            value={searchTags}
                                            id="value"
                                            options={[]}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip key={index} label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            onChange={handleTagsChange}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Conteúdo"
                                                    InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </FormCard>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12}>
                        <SaleTable
                            setLoadingData={setLoadingData}
                            setValuesList={setValuesList}
                            field={field}
                            setField={setField}
                            mapFields={mapFields}
                            priority={priority}
                            searchContent={searchContent}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Sale;
