import React, { useState } from 'react';
import { api } from '../../services/api';
import FormGeneralBank from '../../components/forms/formGeneralBank';
import FormNationalAddress from '../../components/forms/formNationalAddress';
import FormContacts from '../../components/forms/formContacts';
import PageCreate from '../../components/pageCreate';

export default function FinInstitutionCreate() {
    const base = {
        "tipo": "Jurídica",
        "razaoSocial": "",
        "cnpj": "",
        "ativoDesde": "",
        "limite": {
            "valor": "",
            "moeda": "USD"
        },
        "contatos": [
            {
                "nome": "",
                "email": "",
                "celular": "",
                "telefone": ""
            }
        ],
        "endereco": {
            "logradouro": "",
            "numero": "",
            "complemento": "",
            "bairro": "",
            "cidade": "",
            "estado": "",
            "pais": "",
            "zipCode": "",
            "caixaPostal": "null"
        }
    }

    const [inputFields, setInputFields] = useState(base);
    const [running, setRunning] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const createNewInstitution = async item => {
        const newItem = { ...item };
        const newLimiteValor = newItem.limite.valor;
        newItem.limite.valor = realToFloat(newLimiteValor);
        newItem.limiteCredito = realToFloat(newLimiteValor);
        const resp = api.postFinInstitution(JSON.stringify(newItem));
        return resp;
    }

    function realToFloat(value) {
        let newValue = value.replace(/\./g, "");
        newValue = newValue.replace(",", ".");
        return parseFloat(newValue);
    }

    const handleSubmit = async e => {
        setRunning(true);
        e.preventDefault();
        const resp = await createNewInstitution(inputFields);
        if (resp) {
            setMessage("Banco cadastrado!");
            setSeverity("success");
            setInputFields(base);
        } else {
            setMessage("Erro ao cadastrar banco!");
            setSeverity("error");
        }
        setOpen(true);
    }

    return (
        <PageCreate title="Nova Instituição" handleSubmit={handleSubmit} message={message} severity={severity} running={running} setRunning={setRunning} open={open} setOpen={setOpen}>
            <FormGeneralBank inputFields={inputFields} setInputFields={setInputFields} running={running} />
            <FormNationalAddress inputFields={inputFields} setInputFields={setInputFields} running={running} />
            <FormContacts inputFields={inputFields} setInputFields={setInputFields} running={running} />
        </PageCreate>
    );
}
