import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import App from './main/App';
import { store } from './store';

import { QueryClientProvider } from '@tanstack/react-query'

import queryClient from '../src/query/queryClient'

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </QueryClientProvider>
);
