import { Add, Remove } from '@mui/icons-material';
import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Typography,
} from '@mui/material';
import {
    ContractNumberMask,
    MoneyTextMask,
    MoneyTextMask4
} from '../../components/masks';
import React, { useEffect, useState } from 'react';
import { getUserId, isAdmin, isManager } from '../../services/auth';
import { useNavigate, useParams } from 'react-router-dom';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Autocomplete from '@mui/material/Autocomplete';
import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Delete from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Edit from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAlert from '@mui/material/Alert';
import ReceiptCard from './cardReceipt';
import SaleCard from './cardSale';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { api } from '../../services/api';
import dayjs from 'dayjs';
import { formatFloatToReal } from "../../common/utils";
import { red } from '@mui/material/colors'
import { styled } from '@mui/material/styles';
import { sumReceivedValues } from '../../common/utils';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function realToFloat(value) {
    let newValue = value.replace(/\./g, "");
    newValue = newValue.replace(",", ".");
    return parseFloat(newValue);
}

function floatToReal(value, precision = 4) {
    let newValue = value.toFixed(precision).replace(".", ",");
    return newValue;
}

const AccView = () => {
    const { id } = useParams();
    const [fields, setFields] = useState({ "arquivos": [], "arquivos_list": [] });
    const [fieldsBack, setFieldsBack] = useState({ "arquivos_list": [] });

    const [editing, setEditing] = useState(false);
    const [running, setRunning] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [canDelete, setCanDelete] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    // const today = dayjs();
    // const todayStartOfTheDay = today.startOf('day');

    const [sales, setSales] = useState({});
    const [salesFilter, setSalesFilter] = useState({});
    const [receipts, setReceipts] = useState([]);
    const [receiptsBack, setReceiptsBack] = useState([]);

    const navigate = useNavigate();

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = async (needRemove) => {
        if (needRemove) {
            setRunning(true);
            setOpenDialog(false);
            const resp = await deleteAcc(id);
            if (resp) {
                setMessage("Acc excluído com sucesso!");
                setSeverity("success");
                navigate("/accs");
            } else {
                setMessage("Erro ao excluir Acc!");
                setSeverity("error");
                setRunning(false);
                setEditing(false);
            }
        } else {
            setOpenDialog(false);
            setRunning(false);
            setEditing(false);
        }
    };

    const handleSaleSelect = (value) => {
        if (value) {
            let receiptsFiltered = receiptsBack.filter(a => a.venda_id === value.id);
            let salesFiltered = Object.values(salesFilter).filter(a => a.id === value.id);
            setReceipts(receiptsFiltered);
            setSales(salesFiltered);
        } else {
            setReceipts(receiptsBack);
            setSales(salesFilter);
        }

    }

    const handleAddFile = (target, idx) => {
        let filesList = fields.arquivos_list;
        filesList[idx]["file"] = target.files[0];
        setFields((fields) => ({
            ...fields, arquivos_list: filesList
        }));
    };

    const handleAddName = (target, idx) => {
        let filesList = fields.arquivos_list;
        filesList[idx]["field"] = target;
        setFields((fields) => ({
            ...fields, arquivos_list: filesList
        }));
    };

    const updateAcc = async (item, itemBack) => {
        const formData = new FormData();
        for (let i = 0; i < item.arquivos_list.length; i++) {
            if (item.arquivos_list[i]["file"] !== "" && item.arquivos_list[i]["field"] !== "") {
                formData.append(item.arquivos_list[i]["field"], item.arquivos_list[i]["file"]);
            }
        }
        delete item.contratoFile;
        delete item.usuarios;
        delete item.recebimentos;
        const newItem = { ...item };
        newItem.banco = item.banco.id;
        newItem.exportadora = item.exportadora.id;
        newItem.taxaCambial = newItem.taxaCambial !== "" ? realToFloat(newItem.taxaCambial) : itemBack.taxaCambial;
        newItem.taxaJuros = newItem.taxaJuros !== "" ? realToFloat(newItem.taxaJuros) : itemBack.taxaJuros;
        newItem.valorMonetarioDolar = newItem.valorMonetarioDolar !== "" ? realToFloat(newItem.valorMonetarioDolar) : itemBack.valorMonetarioDolar;
        newItem.valorMonetarioReal = newItem.valorMonetarioReal !== "" ? newItem.valorMonetarioReal : itemBack.valorMonetarioReal;

        newItem.usuario = getUserId();

        if ((newItem.dataLiquidacaoContrato !== itemBack.dataLiquidacaoContrato) || (newItem.taxaJuros !== itemBack.taxaJuros)) {
            newItem.renegociacao = true;
        }
        const jsonBody = JSON.stringify(newItem);
        const blob = new Blob([jsonBody], {
            type: 'application/json'
        });
        formData.append("body", blob);
        const resp = await api.putAcc(formData, item.id);
        return resp;
    }

    const deleteAcc = async id => {
        const resp = api.delAcc(id);
        return resp;
    }

    const downloadFile = async (key) => {
        const resp = await api.getFileAcc(fields.id, key);
        const link = document.createElement('a');
        link.href = resp;
        link.setAttribute(
            'download',
            `FileName.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const sumAllSalesValues = (sale) => {
        let totalReceivedItem = sumReceivedValues(sale.recebimentos);
        sale.totalQuitado = totalReceivedItem;
        sale.totalEmAberto = sale.totalDolar - totalReceivedItem;
        return sale;
    }

    const handleSubmit = async e => {
        setRunning(true);
        e.preventDefault();
        const resp = await updateAcc(fields, fieldsBack);
        if (resp) {
            setMessage("Atualizado com sucesso!");
            setSeverity("success");
            setFields({
                ...resp, arquivos_list: []
            });
            setFieldsBack({
                ...resp, arquivos_list: []
            });
        } else {
            setMessage("Erro ao atualizar!");
            setSeverity("error");
        }
        setRunning(false);
        setEditing(false);
        setOpen(true);
    }

    const handleClose = () => {
        setRunning(false);
        setOpen(false);
    };

    const handleEdit = () => {
        setEditing(true);
        setFields({
            ...fields, valorMonetarioDolar: "", taxaCambial: "", valorMonetarioReal: "",
            taxaJuros: ""
        })
    }

    const cancelSubmit = () => {
        setFields(fieldsBack);
        setEditing(false);
    }

    useEffect(() => {
        let mounted = true;
        let receipts = [];
        let uniqueSalesIds = [];
        api.getOneAcc(id)
            .then(resp => {
                if (mounted && resp) {

                    setFields({
                        ...fields, 
                        ...resp, 
                        nomeUsuario: resp.usuarios ? resp.usuarios.nome : 'Usuário não encontrado'
                    });
                    setFieldsBack({
                        ...fields, 
                        ...resp, 
                        nomeUsuario: resp.usuarios ? resp.usuarios.nome : 'Usuário não encontrado'
                    });
    
                    receipts = resp.recebimentos;
    
                    if (receipts.length === 0 && isAdmin()) {
                        setCanDelete(true);
                    }
    
                    if (isAdmin() || isManager()) {
                        setCanEdit(true);
                    }
    
                    setReceipts(receipts);
                    setReceiptsBack(receipts);

                    uniqueSalesIds = [...new Set(receipts.map(item => item.venda_id))];

                    uniqueSalesIds.forEach(async (id) => {
                        const resp = await api.getOneSale(id);
                        setSales((sales) => ({
                            ...sales, [id]: sumAllSalesValues(resp)
                        }));
                        setSalesFilter((sales) => ({
                            ...sales, [id]: sumAllSalesValues(resp)
                        }));
                    });
                }
            })
        return () => {
            mounted = false;
        }
    }, []);
    

    return (
        <Container component="form"
            maxWidth="xg"
            sx={{ mt: 4, mb: 4 }}
            onSubmit={handleSubmit}>
            <Dialog
                open={openDialog}
                onClose={() => handleCloseDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Exclusão do Acc {fields.contratoBanco}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Certeza que deseja excluir o contrato {fields.contratoBanco} ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleCloseDialog(false)} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => handleCloseDialog(true)} color="error" autoFocus>
                    Excluir
                </Button>
                </DialogActions>
            </Dialog>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
            <Paper variant="outlined" sx={{ width: '100%', mb: 1 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        ml: 2,
                        mr: 2,
                        mb: 0
                    }}
                >
                    <Typography
                        sx={{ m: 2 }}
                        variant="h5"
                    >
                        Informações do ACC
                    </Typography>
                    {canEdit && !editing ?
                        <Box sx={{ m: 1 }}>
                            <Button
                                style={{ textTransform: 'none' }}
                                startIcon={(<Edit style={{ fontSize: "12px" }} />)}
                                sx={{ mr: 2 }}
                                size="small"
                                variant="contained"
                                disabled={running}
                                disableElevation
                                onClick={() => handleEdit(id)}
                            >
                                Editar
                            </Button>
                            { canDelete && <Button
                                style={{ textTransform: 'none' }}
                                startIcon={(<Delete style={{ fontSize: "12px" }} />)}
                                color="error"
                                variant="outlined"
                                size="small"
                                disabled={running}
                                disableElevation
                                onClick={() => handleOpenDialog()}
                            >
                                Excluir
                            </Button> }
                        </Box> : null}
                </Box>
            </Paper>
            <Grid item xs={12}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 } }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={false} id="panel-tel-header">
                                    <Typography>Informações do Contrato</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                InputProps={{
                                                    readOnly: true,
                                                    sx: { backgroundColor: '#f6f6f6' }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                id="exportadora"
                                                name="exportadora"
                                                label="Exportadora"
                                                value={fields.exportadora ? fields.exportadora.razaoSocial : ""}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                InputProps={{
                                                    readOnly: true,
                                                    sx: { backgroundColor: '#f6f6f6' }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                id="banco"
                                                name="banco"
                                                label="Banco"
                                                value={fields.banco ? fields.banco.razaoSocial : ""}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField rows={3}
                                                size='small'
                                                disabled={running}
                                                required
                                                id="contratoBacen"
                                                name="contratoBacen"
                                                label="Contrato Bacen"
                                                fullWidth
                                                value={fields.contratoBacen}
                                                onChange={event => setFields({
                                                    ...fields, contratoBacen: event.target.value
                                                })}
                                                InputProps={{
                                                    readOnly: !editing,
                                                    inputComponent: ContractNumberMask,
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#f6f6f6' }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField rows={3}
                                                size='small'
                                                disabled={running}
                                                required
                                                id="contratoBanco"
                                                name="contratoBanco"
                                                label="Contrato Banco"
                                                fullWidth
                                                value={fields.contratoBanco}
                                                onChange={event => setFields({
                                                    ...fields, contratoBanco: event.target.value
                                                })}
                                                InputProps={{
                                                    readOnly: !editing,
                                                    inputComponent: ContractNumberMask,
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#f6f6f6' }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField rows={3}
                                                size='small'
                                                disabled={running}
                                                required
                                                id="codigoMoeda"
                                                name="codigoMoeda"
                                                label="Código da moeda"
                                                fullWidth
                                                value={fields.codigoMoeda}
                                                onChange={event => setFields({
                                                    ...fields, codigoMoeda: event.target.value
                                                })}
                                                InputProps={{
                                                    readOnly: !editing,
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#f6f6f6' }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                required
                                                id="contaDeposito"
                                                name="contaDeposito"
                                                label="Conta para Depósito"
                                                fullWidth
                                                value={fields.contaDeposito}
                                                onChange={event => setFields({
                                                    ...fields, contaDeposito: event.target.value
                                                })}
                                                InputProps={{
                                                    readOnly: !editing,
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#f6f6f6' }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                required
                                                id="naturezaOperacao"
                                                name="naturezaOperacao"
                                                label="Natureza da Operação"
                                                fullWidth
                                                value={fields.naturezaOperacao}
                                                onChange={event => setFields({
                                                    ...fields, naturezaOperacao: event.target.value
                                                })}
                                                InputProps={{
                                                    readOnly: !editing,
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#f6f6f6' }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        {editing ? <Grid item xs={12} sm={2}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                <DatePicker
                                                    size='small'
                                                    id="dataContrato"
                                                    name="dataContrato"
                                                    label="Data de Negociação"
                                                    defaultValue={dayjs(fields.dataContrato).startOf('day')}
                                                    minDate={dayjs(fields.dataContrato).startOf('day')}
                                                    format="DD/MM/YYYY"
                                                    onChange={event => setFields({
                                                        ...fields, dataContrato: event
                                                    })}
                                                />

                                            </LocalizationProvider>
                                        </Grid> :
                                            <Grid item xs={12} sm={2}>
                                                <TextField
                                                    size='small'
                                                    required
                                                    id="dataContrato"
                                                    name="dataContrato"
                                                    label="Data de Negociação"
                                                    fullWidth
                                                    value={dayjs(fields.dataContrato).format('DD/MM/YYYY').toString()}
                                                    InputProps={{
                                                        readOnly: true,
                                                        sx: { backgroundColor: '#f6f6f6' }
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>}
                                        {editing ? <Grid item xs={12} sm={2}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                <DatePicker
                                                    size='small'
                                                    id="dataCredito"
                                                    name="dataCredito"
                                                    label="Data de Crédito"
                                                    defaultValue={dayjs(fields.dataCredito).startOf('day')}
                                                    minDate={dayjs(fields.dataCredito).startOf('day')}
                                                    format="DD/MM/YYYY"
                                                    onChange={event => setFields({
                                                        ...fields, dataCredito: event
                                                    })}
                                                />

                                            </LocalizationProvider>
                                        </Grid> :
                                            <Grid item xs={12} sm={2}>
                                                <TextField
                                                    size='small'
                                                    required
                                                    id="dataCredito"
                                                    name="dataCredito"
                                                    label="Data de Crédito"
                                                    fullWidth
                                                    value={dayjs(fields.dataCredito).format('DD/MM/YYYY').toString()}
                                                    InputProps={{
                                                        readOnly: true,
                                                        sx: { backgroundColor: '#f6f6f6' }
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>}
                                        {editing ?
                                            <Grid item xs={12} sm={2}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                    <DatePicker
                                                        size='small'
                                                        sx={{ width: '100%' }}
                                                        label="Data de Liquidação"
                                                        id="dataLiquidacao"
                                                        name="dataLiquidacao"
                                                        defaultValue={dayjs(fields.dataLiquidacaoContrato).startOf('day')}
                                                        minDate={dayjs(fields.dataLiquidacaoContrato).startOf('day')}
                                                        format="DD/MM/YYYY"
                                                        onChange={event => setFields({
                                                            ...fields, dataLiquidacaoContrato: event
                                                        })}
                                                    />

                                                </LocalizationProvider>
                                            </Grid> :
                                            <Grid item xs={12} sm={2}>
                                                <TextField
                                                    size='small'
                                                    required
                                                    id="dataLiquidacao"
                                                    name="dataLiquidacao"
                                                    label="Data de Liquidação"
                                                    fullWidth
                                                    value={dayjs(fields.dataLiquidacaoContrato).format('DD/MM/YYYY').toString()}
                                                    InputProps={{
                                                        readOnly: true,
                                                        sx: { backgroundColor: '#f6f6f6' }
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>}
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                id="valorMonetarioDolar"
                                                name="valorMonetarioDolar"
                                                label="Total em Dólar"
                                                helperText={editing ? "Valor atual: " + formatFloatToReal(fieldsBack.valorMonetarioDolar) : ""}
                                                fullWidth
                                                onChange={event => setFields({
                                                    ...fields, valorMonetarioDolar: event.target.value,
                                                    valorMonetarioReal: fields.taxaCambial ? (realToFloat(event.target.value) * realToFloat(fields.taxaCambial)).toFixed(4) : (realToFloat(event.target.value) * fieldsBack.taxaCambial).toFixed(4)
                                                })}
                                                value={editing ? fields.valorMonetarioDolar : formatFloatToReal(fieldsBack.valorMonetarioDolar)}
                                                InputProps={{
                                                    readOnly: running,
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    inputComponent: editing ? MoneyTextMask : null,
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#f6f6f6' }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                id="taxaCambial"
                                                name="taxaCambial"
                                                label="Taxa Cambial"
                                                helperText={editing && fieldsBack.taxaCambial ? "Valor atual: " + formatFloatToReal(fieldsBack.taxaCambial) : ""}
                                                fullWidth
                                                onChange={event => setFields({
                                                    ...fields, taxaCambial: event.target.value,
                                                    valorMonetarioReal: fields.valorMonetarioDolar ? (realToFloat(fields.valorMonetarioDolar) * realToFloat(event.target.value)).toFixed(4) : (fieldsBack.valorMonetarioDolar * realToFloat(event.target.value)).toFixed(4)
                                                })}
                                                value={formatFloatToReal(fields.taxaCambial)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                                    inputComponent: editing ? MoneyTextMask4 : null,
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#f6f6f6' }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                id="valorMonetarioReal"
                                                name="valorMonetarioReal"
                                                label="Total em Reais"
                                                helperText={editing ? "Valor atual: " + formatFloatToReal(fieldsBack.valorMonetarioReal) : ""}
                                                fullWidth
                                                value={formatFloatToReal(fields.valorMonetarioReal)}
                                                InputProps={{
                                                    readOnly: true,
                                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#f6f6f6' }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                id="taxaJuros"
                                                name="taxaJuros"
                                                label="Taxa Juros"
                                                fullWidth
                                                helperText={editing ? "Valor atual: " + formatFloatToReal(fieldsBack.taxaJuros) : ""}
                                                onChange={event => setFields({
                                                    ...fields, taxaJuros: event.target.value,
                                                })}
                                                value={formatFloatToReal(fields.taxaJuros)}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">% A.A</InputAdornment>,
                                                    inputComponent: editing ? MoneyTextMask4 : null,
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#f6f6f6' }
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size='small'
                                                InputProps={{
                                                    readOnly: true,
                                                    style: { fontSize: "16px" },
                                                    sx: { backgroundColor: '#fafafa' }
                                                }}
                                                InputLabelProps={{ shrink: true}}
                                                inputProps={{
                                                    maxLength: 60,
                                                }}
                                                id="usuario"
                                                name="usuario"
                                                label="Cadastrado por"
                                                value={fields.nomeUsuario  || "Não disponível"}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={false} id="panel-tel-header">
                                    <Typography>Histórico de Renegociações</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <List dense sx={{ width: '100%' }}>
                                                {fields.historico && Object.entries(fields.historico).map(([key, value]) => (
                                                    <React.Fragment key={key}>
                                                        <ListItem key={key} alignItems="flex-start">
                                                            <ListItemText
                                                                primary={"Liquidação " + dayjs(value.dataLiquidacaoContrato).format('DD/MM/YYYY').toString()}
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Typography
                                                                            sx={{ display: 'inline' }}
                                                                            component="span"
                                                                            variant="body"
                                                                            color="text.secondary"
                                                                        >
                                                                            {"Valor $ " + formatFloatToReal(value.valorMonetarioDolar)}
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{ display: 'inline' }}
                                                                            component="span"
                                                                            variant="body"
                                                                            color="text.secondary"
                                                                        >
                                                                            {" - Taxa de juros " + formatFloatToReal(value.taxaJuros) + "%"}
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{ display: 'inline' }}
                                                                            component="span"
                                                                            variant="body"
                                                                            color="text.secondary"
                                                                        >
                                                                            {" - Taxa cabial R$ " + formatFloatToReal(value.taxaCambial)}
                                                                        </Typography>

                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider component="li" />
                                                    </React.Fragment>
                                                ))}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {!editing && <Grid item xs={12} sm={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={false} id="panel-tel-header">
                                    <Typography>Recebimentos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <Autocomplete
                                                size='small'
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(option) => option.code}
                                                options={Object.values(salesFilter)}
                                                onChange={(event, value) => handleSaleSelect(value)}
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => <TextField {...params} label="Filtro venda" />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <List dense sx={{ width: '100%', overflow: 'auto', bgcolor: '#fff', display: 'flex', flexDirection: 'row' }}>
                                                {sales && Object.entries(sales).map(([key, value]) => (
                                                    <ListItem key={key}>
                                                        <SaleCard
                                                            message={'Venda KAV-' + value.code}
                                                            client={value.importadora.razaoSocial}
                                                            produto={value.produto.nome}
                                                            total={"$ " + formatFloatToReal(value.totalDolar, 2)}
                                                            closed={"$ " + formatFloatToReal(value.totalEmAberto, 2)}
                                                            open={"$ " + formatFloatToReal(value.totalQuitado, 2)}
                                                            others={value}
                                                        >
                                                            {/* <CurrencyExchangeIcon sx={{ fontSize: 20 }} color='warning' /> */}
                                                        </SaleCard>
                                                    </ListItem>

                                                ))}
                                            </List>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <List dense sx={{ width: '100%', overflow: 'auto', bgcolor: '#fff', display: 'flex', flexDirection: 'row' }}>
                                                {receipts && Object.entries(receipts).map(([key, value]) => (
                                                    <ListItem key={key}>
                                                        <ReceiptCard
                                                            message='Recebimento'
                                                            values={"$ " + formatFloatToReal(value.valorRecebido, 2)}
                                                            arquivos={value.arquivos}
                                                            id={value.id}
                                                        >
                                                        </ReceiptCard>
                                                    </ListItem>

                                                ))}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>}
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={false} id="panel-tel-header">
                                    <Typography>Arquivos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <List dense sx={{ width: '100%', maxWidth: 300, bgcolor: '#fff', display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                {fields.arquivos && Object.entries(fields.arquivos).map(([key, value]) => (
                                                    <ListItem key={key}
                                                    >
                                                        <Button color="info" style={{ textTransform: 'none' }} variant="outlined" onClick={() => downloadFile(key)}
                                                            startIcon={<FileDownloadIcon />}
                                                        >
                                                            {key}
                                                        </Button>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Grid>
                                        {editing ? <Grid item xs={12} sm={12}>
                                            <IconButton
                                                color="primary"
                                                aria-label="add file"
                                                size="small"
                                                onClick={() => {
                                                    let filesList = fields.arquivos_list;
                                                    filesList.push({ "field": "", "file": "" });
                                                    setFields({ ...fields, arquivos_list: filesList });
                                                }}
                                            >
                                                <Add />
                                            </IconButton>
                                            <IconButton
                                                sx={{ color: red[700] }}
                                                aria-label="rm file"
                                                size="small"
                                                onClick={() => {
                                                    if (fields.arquivos_list.length > 1) {
                                                        let filesList = fields.arquivos_list;
                                                        filesList.pop();
                                                        setFields({ ...fields, arquivos_list: filesList });
                                                    }
                                                }}
                                            >
                                                <Remove />
                                            </IconButton>
                                        </Grid> : null}
                                        {editing ?
                                            fields.arquivos_list.map((field, idx) => {
                                                return (
                                                    <React.Fragment key={idx}>
                                                        <Grid item xs={12} sm={3}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                disabled={running}
                                                                id={`${idx + 1}`}
                                                                name="filename"
                                                                label={`Arquivo ${idx + 1}`}
                                                                value={fields.arquivos_list[idx]["field"]}
                                                                onChange={event => handleAddName(event.target.value, idx)}
                                                                // InputProps={{
                                                                //     inputComponent: TelTextMask
                                                                // }}
                                                                // fullWidth
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                disabled={running}
                                                                id={`${idx + 1}`}
                                                                name="arquivo"
                                                                label={`Arquivo ${idx + 1}`}
                                                                value={fields.arquivos_list[idx]["file"].name}
                                                                InputLabelProps={{ shrink: true }}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment:
                                                                        <Button
                                                                            size="small"
                                                                            component="label"
                                                                            color="primary"
                                                                            variant="outlined">
                                                                            <input
                                                                                hidden
                                                                                accept="pdf/*"
                                                                                multiple
                                                                                type="file"
                                                                                onChange={event => handleAddFile(event.target, idx)}
                                                                            >
                                                                            </input>
                                                                            <UploadFileIcon />
                                                                        </Button>
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}></Grid>
                                                    </React.Fragment>
                                                )
                                            })
                                            : null}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {editing ?
                            <Grid item >
                                <Grid container spacing={3} justifyContent="center">
                                    <Grid item xs={12} sm={6}>
                                        <LoadingButton
                                            style={{ textTransform: 'none' }}
                                            size='small'
                                            loading={running}
                                            type="submit"
                                            startIcon={(<SaveIcon style={{ fontSize: "12px" }} />)}
                                            color="primary"
                                            variant="contained"
                                            disableElevation
                                            sx={{ width: "150px" }}
                                        >
                                            Salvar
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <LoadingButton
                                            style={{ textTransform: 'none' }}
                                            size='small'
                                            loading={running}
                                            startIcon={(<CancelIcon style={{ fontSize: "12px" }} />)}
                                            color="primary"
                                            variant='outlined'
                                            disableElevation
                                            onClick={() => cancelSubmit()}
                                            sx={{ width: "150px" }}
                                        >
                                            Cancelar
                                        </LoadingButton>
                                    </Grid>
                                </Grid>

                            </Grid>
                            : null}
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    );
}

export default AccView;
