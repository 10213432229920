import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import { formatFloatToDolar, sumReceivedValues } from "../../common/utils";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useAccs } from '../../apis/accs';

const headCells = [
  {
    id: 'razaoSocial',
    numeric: false,
    disablePadding: false,
    label: 'Banco',
  },
  {
    id: "contratoBanco",
    numeric: false,
    disablePadding: false,
    label: 'Contrato',
  },
  {
    id: "contratoBacen",
    numeric: false,
    disablePadding: false,
    label: 'Bacen',
  },
  {
    id: 'valorMonetarioDolar',
    numeric: false,
    disablePadding: false,
    label: 'Total',
  },
  {
    id: 'totalQuitado',
    numeric: false,
    disablePadding: false,
    label: 'Quitado',
  },
  {
    id: 'totalEmAberto',
    numeric: false,
    disablePadding: false,
    label: 'Pendente',
  },
  {
    id: 'taxaJuros',
    numeric: false,
    disablePadding: false,
    label: 'Juros',
  },
  {
    id: 'dataContrato',
    numeric: false,
    disablePadding: false,
    label: 'Data',
  },
  {
    id: 'dataLiquidacaoContrato',
    numeric: false,
    disablePadding: false,
    label: 'Vencimento',
  },
  {
    id: 'renegociado',
    numeric: false,
    disablePadding: false,
    label: 'Renegociado',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            //sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontSize: '12px', fontWeight: 'bold' }}
          >
            {headCell.label}
            {/*
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>*/}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  //onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  //order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  //orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const AccTable = props => {
  const { field, setField, mapFields, priority, searchContent, setLoadingData } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('dataContrato');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalElements, setTotalElements] = React.useState(0);
  const [accList, setAccList] = React.useState([]);
  const navigate = useNavigate();

  const { data, isLoading, isError, error, isFetching, refetch, fetchNextPage } = useAccs(rowsPerPage, priority == "open", mapFields[field], searchContent, priority == "closed")

  React.useEffect(() => {
    const loadingStatus = isLoading || isFetching;
    setLoadingData(loadingStatus);

    if (!data) return;

    const allAccs = data.pages.flatMap(page => page.content || []);

    setTotalElements(data.pages[0].totalElements);

    sumAllAccValues(allAccs);

    setAccList(allAccs);

    if (isError) {
      console.error("Erro ao carregar dados:", error);
    }
  }, [data, isLoading, isError, error, isFetching]);

  const sumAllAccValues = (accs) => {
    let totalValue = 0;
    let totalReceived = 0;
    accs.forEach(acc => {
      let totalReceivedAcc = sumReceivedValues(acc.recebimentos);
      totalValue += acc.valorMonetarioDolar;
      totalReceived += totalReceivedAcc;

      acc.totalQuitado = totalReceivedAcc;
      acc.totalEmAberto = acc.valorMonetarioDolar - totalReceivedAcc;
    });
    return [totalValue - totalReceived, totalValue, totalReceived];
  }

  React.useEffect(() => {
    setPage(0);
    refetch();
}, [searchContent, priority]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchNextPage();
  };

  const goToAcc = (id) => {
    navigate(`/accs/${id}`, { id: id });
  }

  const handleClick = (id) => {
    goToAcc(id);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    refetch();
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - accList.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Card variant="outlined" sx={{ borderRadius: '10px' }}>
        <CardContent>
          <Grid container alignItems="center">
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size='small'
              >
                <EnhancedTableHead
                  // numSelected={selected.length}
                  //order={order}
                  //orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  //onRequestSort={handleRequestSort}
                  rowCount={accList.length}
                />
                <TableBody>
                  {accList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                        // style={{ height: '10px' }}
                        >
                          <TableCell>
                          </TableCell>
                          <TableCell style={{ width: '15%', fontSize: '11px' }} align="left" padding="normal">{row.banco.razaoSocial}</TableCell>
                          <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{row.contratoBanco}</TableCell>
                          <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{row.contratoBacen}</TableCell>
                          <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{formatFloatToDolar(row.valorMonetarioDolar)}</TableCell>
                          <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{formatFloatToDolar(row.totalQuitado)}</TableCell>
                          <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{formatFloatToDolar(row.totalEmAberto)}</TableCell>
                          <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{row.taxaJuros}% A.A</TableCell>
                          <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{dayjs(row.dataContrato).format('DD/MM/YYYY').toString()}</TableCell>
                          <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{dayjs(row.dataLiquidacaoContrato).format('DD/MM/YYYY').toString()}</TableCell>
                          <TableCell style={{ width: '5%', fontSize: '11px' }} align="left" padding="normal">{row.historico.length !== 0 ? "Sim" : "Não"}</TableCell>
                          <TableCell style={{ width: '5%', fontSize: '11px' }} align="left" padding="normal">
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                              <CircularProgress variant="determinate" color={(row.totalQuitado / row.valorMonetarioDolar) * 100 >= 80 ? "success" : "warning"} value={(row.totalQuitado / row.valorMonetarioDolar) * 100} />
                              <Box
                                sx={{
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  position: 'absolute',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <Typography variant="caption" component="div" color="text.primary" style={{ fontSize: "8px" }}>
                                  {`${Math.round((row.totalQuitado / row.valorMonetarioDolar) * 100)}%`}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell style={{ width: '5%' }} align="left" padding="normal">
                            <IconButton
                              onClick={(event) => handleClick(row.id)}
                              size='small'>
                              <VisibilityIcon fontSize='small' />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75, 100]}
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AccTable;