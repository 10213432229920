import { Box, CircularProgress } from "@mui/material";

export default function LoadingIndicator() {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 35,
            width: 35,
            borderRadius: "50%",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            backgroundColor: "#fff"
        }}>
            <CircularProgress size={25} />
        </Box>
    )
}