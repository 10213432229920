import React, { useState } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';

// import Title from './Title';

function preventDefault(event) {
    event.preventDefault();
}

export default function ValueOpenCard(props) {
    const message = props.message;
    const values = props.values;
    const [currentDate, setCurrentDate] = useState(dayjs(new Date()).format('DD/MM/YYYY - hh:mm:ss').toString());
    return (
        <Box sx={{ minWidth: 50 }}>
            <Card variant="outlined" sx={{ borderRadius: '10px', paddingX: '5px'}}>
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid item xs={10}>
                            <Typography style={{ fontSize: '13px' }} color="primary" gutterBottom>
                                {message}
                            </Typography>
                            <Typography style={{ fontSize: '17px' }}>
                                {values}
                            </Typography>
                            {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
                                Atualizado em {currentDate}
                            </Typography> */}
                            <Grid item xs={12} paddingTop={3}>
                                <Typography color="text.secondary" style={{ fontSize: '10px' }}>
                                    Atualizado em {currentDate}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            {props.children}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}