import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePrereceipt, getFilePreReceipt, deletePrereceipt } from '../../apis/prereceipt';
import { getReceivingByIds } from '../../apis/receiving';
import { getAccsByIds } from '../../apis/accs';
import { useSale } from '../../apis/sales';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Snackbar,
    Alert,
    Stack,
    Paper,
    Grid,
    Box,
    Typography,
    TextField,
    List,
    ListItem,
} from '@mui/material';

import {
    Delete,
    FileDownload,
    ChangeCircle
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { formatFloatToReal, sumReceivedValues } from '../../common/utils';
import { isAdmin } from '../../services/auth';
import FormCard from '../../components/forms/formCard';
import { ReceiptCard } from './cardReceipt';

const PreReceiptUpdate = props => {
    const { id } = useParams();
    const [canConvert, setCanConvert] = useState(false);
    const [cardsContent, setCardsContent] = useState([]);
    const [prereceiving, setPreReceiving] = useState(null);
    const [receivingData, setReceivingData] = useState([]);
    const [accsData, setAccsData] = useState([]);
    const [open, setOpen] = useState(false);
    const [running, setRunning] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [canDelete, setCanDelete] = useState(false);

    const { data, isLoading, isFetched } = usePrereceipt(id);
    const { data: saleData, isFetched: isFetchedSale } = useSale(prereceiving?.venda.id);

    const navigate = useNavigate();

    useEffect(() => {
        if (isFetchedSale && data) {
            let receivingListTmp = [];
            let accsListTmp = [];
            saleData.recebimentos.map((item) => {
                if (data.recebimentos.includes(item?.id)) {
                    receivingListTmp.push(item?.id);
                    accsListTmp.push(item?.acc_id??'');
                }
                return null;
            });
            
            const fetchData = async () => {
                const accsData = await getAccsByIds(accsListTmp);
                const receivingData = await getReceivingByIds(receivingListTmp);

                setAccsData(accsData);
                setReceivingData(receivingData);
            };
    
            fetchData();
        }
    }, [isFetchedSale, saleData, data]);

    useEffect(() => {
        const content = [];
        receivingData.map((item) => {
            const acc = accsData.filter((acc) => acc?.id === item?.acc_id ?? '');
            content.push({
                id: item.id,
                banco: acc[0]?.banco.razaoSocial,
                message: `Contrato Bacen ${acc[0]?.contratoBacen}`,
                values: "$ " + formatFloatToReal(item.valorRecebido, 2),
                arquivos: item.arquivos,
                creationDate: dayjs(item.creationDate).format('DD/MM/YYYY').toString()
            });
            return null;
        });
        setCardsContent(content);
    }, [receivingData, accsData]);

    const downloadFile = async (file) => {
        const resp = await getFilePreReceipt(prereceiving.id, file);
        const link = document.createElement('a');
        link.href = resp;
        link.setAttribute(
            'download',
            `FileName.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const handleClose = () => {
        setRunning(false);
        setOpen(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const deletePreReceipt = async (id) => {
        const resp = await deletePrereceipt(id);
        return resp;
    }

    if (isFetched && data && prereceiving == null) {
        setPreReceiving(data);
        if (isAdmin() && data?.recebimentos.length === 1) {
            setCanDelete(true);
        }
    }

    useEffect(() => {

        if (prereceiving != null) {
            setCanConvert((prereceiving?.valorTotal - prereceiving?.valorRecebido) > 0);
        }
    }, [prereceiving]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const handleCloseDialog = async (needRemove) => {
        if (needRemove) {
            setRunning(true);
            setOpenDialog(false);
            const resp = await deletePreReceipt(id);
            if (resp.status === 200) {
                setMessage("Pré-recebimento excluído com sucesso!");
                setSeverity("success");
                navigate("/prereceiving");
            } else {
                setMessage("Erro ao excluir Pré-recebimento!");
                setSeverity("error");
                setRunning(false);
            }
        } else {
            setOpenDialog(false);
            setRunning(false);
        }
    };

    return (
        <Container component="form"
            maxWidth="xg"
            sx={{ mt: 4, mb: 4 }}
            onSubmit={props.handleSubmit}>

            <Dialog
                open={openDialog}
                onClose={() => handleCloseDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Exclusão de Recebimento</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Certeza que deseja excluir o pré-recebimento da venda KAV-{prereceiving?.venda.code} no valor de {"$ " + formatFloatToReal(prereceiving?.valorRecebido, 2)} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => handleCloseDialog(true)}
                        color="error"
                        autoFocus
                        >
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>

            <Paper variant="outlined" sx={{ p: { xs: 2, md: 2 }, borderRadius: '10px' }}>
            <Grid item xs={12} sm={12}>
                <Box
                    sx={{
                        alignItems: 'flex-end',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        ml: 1,
                        mr: 1,
                        mb: 0
                    }}
                >
                    <Typography
                     sx={{ fontSize: "18px" }}
                    >
                        Detalhes do Pré-Recebimento
                    </Typography>
                    <Box sx={{ m: 1 }}>
                        { canDelete && <Button
                            style={{ textTransform: 'none', fontSize: '10px'}}
                            startIcon={(<Delete style={{ fontSize: "10px" }} />)}
                            color="error"
                            variant="outlined"
                            size="small"
                            disabled={running}
                            disableElevation
                            onClick={() => handleOpenDialog()}
                        >
                            Excluir
                        </Button> }
                        { canConvert && <Button
                            style={{ textTransform: 'none', fontSize: '10px', marginLeft: '5px'}}
                            startIcon={(<ChangeCircle style={{ fontSize: "10px" }} />)}
                            variant="outlined"
                            size="small"
                            disabled={running}
                            disableElevation
                            onClick={() => {navigate(`/prereceiving/convert/${id}`)}}
                        >
                            Converter em Recebimento
                        </Button> }
                    </Box>

                </Box>
                <FormCard title="Venda">
                <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="saleCode"
                                    name="saleCode"
                                    label="Código da Venda"
                                    value={"KAV-" + saleData?.code??""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="client"
                                    name="client"
                                    label="Cliente"
                                    value={saleData?.importadora.razaoSocial??""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="dataVenda"
                                    name="dataVenda"
                                    label="Data da Venda"
                                    value={dayjs(saleData?.dataVenda).format('DD/MM/YYYY').toString() || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="produtoCode"
                                    name="produtoCode"
                                    label="Produto Código ERP"
                                    value={saleData?.produto.codigoERP??""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="produtoNome"
                                    name="produtoNome"
                                    label="Produto"
                                    value={saleData?.produto.nome??""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="produtoDescricao"
                                    name="produtoDescricao"
                                    label="Descrição do Produto"
                                    value={saleData?.produto.descricao??""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="totalVenda"
                                    name="totalVenda"
                                    label="Total da Venda"
                                    value={"$ " + formatFloatToReal(saleData?.totalDolar, 2)??""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="totalRecebido"
                                    name="totalRecebido"
                                    label="Total de Recebimentos"
                                    value={"$ " + formatFloatToReal(sumReceivedValues(saleData?.recebimentos), 2)??""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="totalAberto"
                                    name="totalAberto"
                                    label="Total em Aberto"
                                    value={"$ " + formatFloatToReal(saleData?.totalDolar - sumReceivedValues(saleData?.recebimentos), 2) || ""}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                </FormCard>
                <FormCard title="Pré-Recebimento">
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="valorTotal"
                                    name="valorTotal"
                                    label="Total do Pré-Recebimento"
                                    value={"$ " + formatFloatToReal(prereceiving?.valorTotal, 2)??""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="valorRecebido"
                                    name="valorRecebido"
                                    label="Valor Recebido"
                                    value={"$ " + formatFloatToReal(prereceiving?.valorRecebido, 2)??""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="valorPendente"
                                    name="valorPendente"
                                    label="Valor Pendente"
                                    value={"$ " + formatFloatToReal(prereceiving?.valorTotal - prereceiving?.valorRecebido, 2)??""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="dataRecebimento"    
                                    name="dataRecebimento"
                                    label="Data do Pré-Recebimento"
                                    value={dayjs(prereceiving?.creationDate).format('DD/MM/YYYY').toString()??""}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </FormCard>
                    <FormCard title="Recebimentos Vinculados">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} sx={{ overflow: 'scroll' }}>
                                <List 
                                    sx={{
                                        width: '100%',
                                        maxWidth: 400,
                                        bgcolor: '#fff',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: 0,
                                        }}>
                                    {cardsContent && Object.entries(cardsContent).map(([key, value]) => (
                                        <ListItem key={key}>
                                            <ReceiptCard
                                                { ...value }
                                                id={value.id}
                                            >
                                            </ReceiptCard>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </FormCard>
                    <FormCard title="Arquivos do Pré-Recebimento">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <List sx={{ width: '100%', maxWidth: 400, bgcolor: '#fff', display: 'flex', flexDirection: 'row', padding: 0 }}>
                                    {prereceiving?.arquivos && Object.entries(prereceiving?.arquivos).map(([key, value]) => (
                                        <ListItem key={key}>
                                            <Button style={{ textTransform: 'none' }} variant="outlined" onClick={() => downloadFile(key)}
                                                startIcon={<FileDownload />}
                                            >
                                                {key}
                                            </Button>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </FormCard>
            </Grid>
            </Paper>

        </Container>
    )
}

export default PreReceiptUpdate;